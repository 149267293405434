<template>
	<div class="about-nav">
		<div class="logo f-x-c-c">
            <img class="img" src="../image/common/about_logo.png" alt="">
        </div>
        <div class="list">
            <div
                v-for="(item,index) in list"
                :key="index"
                @click="navToPage(item.url)"
                class="list-item f-x-c-c" 
                :class="curPath==item.url ? 'active' :''"
                >
                {{item.title}}
            </div>
        </div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			list:[
                {
                    title:'品牌简介',
                    url:'/about/group'
                },
                {
                    title:'企业蓝图',
                    url:'/about/blueprint'
                },
                {
                    title:'新闻动态',
                    url:'/about/news'
                },
                {
                    title:'门店地址',
                    url:'/about/address'
                },
            ],
            curPath:''
		}
	},
	mounted() {
       this.curPath = this.$route.path
	},
	methods: {
        navToPage(url){
            this.$router.push(url)
        }
	},
}
</script>

<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .about-nav{
        width: 100%;
        height: 45px;
        .logo{
            display: none;
        }
        .list{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #FFFFFF;
            width: 100%;
            height: 45px;
            overflow: hidden;
            .list-item{
                cursor: pointer;
                width: 25%;
                height: 100%;
                background: #FFFFFF;
                box-sizing: border-box;

                
                font-weight: 400;
                font-size: 16px;
                color: #241934;
            }
            .list-item:last-child{
                border-bottom: initial;
            }
            .list-item:hover{
                background: #753CBE;
                color: #FEFEFF;
            }
            .active{
                background: #753CBE;
                color: #FEFEFF;
            }
        }
        
    }
}
@media screen and (min-width: 900px) {
	.about-nav{
        width: 100%;
        height: 680px;
        border-radius: 18px 18px 0px 0px;
        .logo{
            width: 100%;
            height: 200px;
            background: #753CBE;
            border-radius: 18px 18px 0px 0px;
            padding: 60px 36px;
            box-sizing: border-box;
            .img{
                width: 100%;
                height: 82px;
            }
            
        }
        .list{
            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            align-items: center;

            background: #FFFFFF;
            width: 100%;
            border-radius: 0px 0px 18px 18px ;
            overflow: hidden;
            .list-item{
                cursor: pointer;
                width: 100%;
                height: 120px;
                background: #FFFFFF;
                border-bottom: 1px solid #E5E5E5;
                box-sizing: border-box;

                
                font-weight: 400;
                font-size: 36px;
                color: #241934;
            }
            .list-item:last-child{
                border-bottom: initial;
            }
            .list-item:hover{
                background: #753CBE;
                color: #FEFEFF;
            }
            .active{
                background: #753CBE;
                color: #FEFEFF;
            }
        }
        
    }
}
</style>
